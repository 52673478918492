import * as React from 'react'

import {
  AuthenticationProvider,
  useAuthentication,
} from '@procurify/authentication'
import { logoutUrl } from '@procurify/authentication/src/configs'

import { AppLoader } from '@webapp/app/AppLoader'
import { AuthenticationErrorTypes } from '@webapp/models/enums/AuthenticationErrorType'
import './scss/critical.scss'
import { getCSRFToken } from './services/app.services'
import { withBridgeProviders } from '@bridge-components/withBridgeProviders'

const BootstrapAngular = () => {
  const { isLoading, isAuthenticated, userGlobalData, errorCode } =
    useAuthentication()

  if (errorCode === AuthenticationErrorTypes.STAFF_ACCESS_EXPIRED) {
    window.location.replace(logoutUrl)
    return null
  }

  if (isLoading) return <AppLoader />

  if (!isAuthenticated) {
    window.location.replace('/login')

    return null
  }

  window.GlobalRaw = userGlobalData
  window.Global = {
    locale: userGlobalData.system_preferences.locale,
    csrftoken: getCSRFToken(),
    baseCurrency: {
      ...userGlobalData.base_currency,
      description: '',
      base: true,
      active: true,
      _state: null,
    },
    permissions: JSON.parse(
      JSON.stringify(userGlobalData.permissions).toLowerCase()
    ),
    filtered_navigation: userGlobalData.filtered_navigation,
    // no usage
    role: JSON.parse(JSON.stringify(userGlobalData.role).toLowerCase()),
    // no usage
    application_status: userGlobalData.application_status,
    tutorials: {}, // missing
    setup_progress: JSON.parse(
      JSON.stringify(userGlobalData.setup_progress).toLowerCase()
    ),
    feature_switches: userGlobalData.feature_switches,
    company: userGlobalData.company,
    subdomain: userGlobalData.domain,
    domain: location.host,
    env: userGlobalData.env,
    user: {
      company: {
        id: userGlobalData.domain,
        name: userGlobalData.domain,
        // no usage
        created_at: '',
      },
      // no usage
      created_at: null,
      // no usage
      permission: '',
      // no usage
      system_access: JSON.parse(
        JSON.stringify(
          userGlobalData.user_profile.systemaccess_cache
        ).toLowerCase()
      ),
      firstName: userGlobalData.user_profile.firstName,
      lastName: userGlobalData.user_profile.lastName,
      email: userGlobalData.user_profile.email,
      id: `${userGlobalData.user_profile.id}`,
      user: `${userGlobalData.user_profile.user}`,
      // no usage
      notifications: userGlobalData.user_profile.notifications,
      is_superuser: userGlobalData.user_profile.id === 1,
      profile_image: userGlobalData.user_profile.profile_image || '',
      department: userGlobalData.user_profile.department,
      location: userGlobalData.user_profile.department.branch,
      approval_delegatee: userGlobalData.user_profile.approval_delegatee,
    },
    config: {
      MEDIA_URL: userGlobalData.url.media,
    },
    pusher: {
      channel: `${userGlobalData.domain}-${userGlobalData.user_profile.id}-inbox`,
      systemChannel: `${userGlobalData.domain}-${userGlobalData.user_profile.id}-system`,
    },
    // no usage in application
    context: {
      demo_data_cleared: false,
      onboarding_progress: '',
    },
    // no usage in application
    billing_info: {},
    splashScreen: userGlobalData.go_live,
    pix: { host: userGlobalData.pix_host },
    sdk_keys: userGlobalData.sdk_keys,
    domains: userGlobalData.user_profile.domains,
    contentTypes: userGlobalData.contenttypes,
    systemPreferences: userGlobalData.system_preferences,
  }

  import('./procurify.app.init')

  return <AppLoader />
}

const App = () => {
  return (
    <AuthenticationProvider>
      <BootstrapAngular />
    </AuthenticationProvider>
  )
}

export const AuthenticationApp = withBridgeProviders(App)
